.hazard-table table {
  @apply divide-y divide-slate-300 m-0;
}

.hazard-table thead,
.hazard-table th {
  @apply bg-blue-950;
}

.hazard-table th {
  @apply px-3 py-3.5 text-left text-sm font-semibold text-white;
}

.hazard-table th:first-child {
  @apply py-3.5 pl-4 pr-3 sm:pl-6;
}

.hazard-table th:last-child {
  @apply py-3.5 pl-3 pr-4 sm:pr-6;
}

.hazard-table tbody {
  @apply divide-y divide-slate-200 bg-white;
}

.hazard-table tr td {
  @apply whitespace-nowrap px-3 py-4 text-sm text-slate-700;
}

.hazard-table tr td:first-child {
  @apply py-4 pl-4 pr-3 sm:pl-6;
}

.hazard-table tr td:last-child {
  @apply py-4 pl-3 pr-4 sm:pr-6;
}
