
@import url('https://fonts.googleapis.com/css?family=Inter:400,600,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

body {
    font-family: 'Inter', 'Roboto', Arial, sans-serif;
    background-color: #f8fafc;
    margin: 0;
    padding: 0;
}

.container {
  max-width: 100%;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.searchBar {
  composes: common-style;
  margin: 20px 0;
}

.stack-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;
  
}


.functionGroup {
  width: 100%;
  text-align: center;
  flex-basis: calc(25% - 20px);
  
}


.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e0e0e0;
  border-radius: 10px; /* Adding rounded edges */
  box-shadow: -10px -10px 15px rgba(255,255,255,0.5),
            10px 10px 15px rgba(70,70,70,0.12);
            
  
}

.logo-text {
  font-size: 24px;
  font-weight: bold;
  margin-right: 20px;
  flex-shrink: 0;
}

.common-style {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  flex-grow: 1;
  margin: 0 15px;
  margin-bottom: 20px;
  
}

.save-button {
  /* Apply the common styles */
  composes: common-style;
}

.input-text {
  /* Apply the common styles */
  composes: common-style;
}

.header h1 {
  font-size: 32px; /* Make main title larger */
  margin-bottom: 10px; 
}


.box {
  background-color: #f5f5f5;
  position: relative;
  cursor: pointer;
  text-align: center;
  font-size: 24px;
  line-height: 1;
  color: #4e4e4e;
  width: 100px; /* Reduced for mobile */
  height: 100px; /* Reduced for mobile */
  padding: 10px; /* Adjusted padding */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px; /* Increased margin for spacing */
  border-radius: 10px; /* Adding rounded edges */
  box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
            0px 2px 4px rgba(0,0,0,0.1), 
            0px 4px 8px rgba(0,0,0,0.1), 
            0px 8px 16px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease; /* Added box-shadow transition */
  
}

.box:hover {
  background-color: #e0e0e0; /* Light gray on hover */
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2), -5px -5px 15px rgba(255,255,255,0.8); /* Enhanced bevel effect on hover */
}


.product-dropdown:hover {
  box-shadow: 5px 5px 15px rgba(0,0,0,0.2), -5px -5px 15px rgba(255,255,255,0.8); /* Enhanced bevel effect on hover */
}

.product-dropdown[style*="display: block"] {
  display: block !important;
}

.box::before {
  content: "";
  display: block;
  padding-top: 100%;
}

.product-dropdown {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  font-size: 16px;
  border-radius: 10px; /* Adding rounded edges */
  box-shadow: 3px 3px 10px rgba(0,0,0,0.1), -3px -3px 10px rgba(255,255,255,0.7); /* Bevel effect */
}

.box:hover .product-dropdown {
  display: block;
}

.selected {
  background-color: #e0e0e0;
  opacity: 1; /* Selected tab is fully opaque and therefore looks "active" */
}

.product-image {
  width: 100%;             /* occupy full width of the box */
  height: 100%;            /* occupy full height of the box */
  max-width: 50px; /* Reduced for mobile */
  max-height: 50px; /* Reduced for mobile */
  object-fit: contain;     /* image will scale to maintain its aspect ratio while fitting within the box */
  margin: auto;            /* centers the image if it's smaller than the container */
}

.navigation {
  display: flex;
  align-items: center;
}

.navigation button {
  background-color: #898888; /* Changed to a dark gray */
  color: #f7f7f7; /* Light text for contrast */
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added color transition */
  opacity: 0.5;
}

.navigation .selected {
  background-color: #555;
  color: #fff;
  opacity: 1; /* Selected tab is fully opaque and therefore looks "active" */
}

.navigation button:first-child {
  margin-left: 0;
}

.navigation button:hover {
  background-color: #555; /* Lighter gray for hover */
  color: #fff; /* Brighter text on hover */
}


.header h2 {
  font-size: 20px; /* A size that's smaller than the main title but larger than regular text */
  margin-top: 20px; /* Space above the secondary title */
  color: #777; /* Making it a bit lighter so it's secondary */
}

.product-insights-box {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 30px;  /* Increased padding */
  margin-top: 30px;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.15), -5px -5px 15px rgba(255,255,255,0.8);
  max-width: none; /* Increased the max width */
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
            0px 2px 4px rgba(0,0,0,0.1), 
            0px 4px 8px rgba(0,0,0,0.1), 
            0px 8px 16px rgba(0,0,0,0.1);
}

.product-insights-header {
  font-size: 28px; /* Bigger font-size */
  border-bottom: 2px solid #e0e0e0; /* Thicker border */
  padding-bottom: 15px; /* Increased padding */
  margin-bottom: 20px; /* Increased margin */
}

.product-insights-content p {
  font-size: 20px;  /* Bigger font-size */
  margin-top: 15px; /* Increased margin */
  margin-bottom: 15px; /* Increased margin */
}

.community-box {
  background-color: #f5f5f5;
  border-radius: 10px;
  position: relative; /* added to accommodate image and square shape */
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, background-color 0.3s ease;
  aspect-ratio: 1/1; /* Makes the box square-shaped */
  overflow: hidden; /* Ensures content does not spill outside the box */
}

.community-box:hover {
  transform: scale(1.05);
  background-color: #e0e0e0;
}

.community-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
  object-position: center; 
}
.product-insights-image {
  width: 20%;       /* Set to occupy the full width of its container */
  max-width: 500px;  /* Adjust this value based on your desired maximum size */
  display: block;    /* Ensure it takes the full width */
  margin: 20px auto; /* Centers the image and provides some spacing */
  object-fit: contain; /* Image will scale to maintain its aspect ratio while fitting within the box */
  border-radius: 10px; /* Optional: If you want the image corners to be rounded */
}

.community-layout {
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 10px; /* Adjust as needed */
}

.left-panel {
  flex: 1;
  margin: 20px;  /* Takes up 3/4 of the space */
 
}

.right-panel {
  flex: 1;  /* Takes up 1/4 of the space */
  background-color: #e9e9e9; /* Optional: Give it a light gray background */
  padding: 20px;
  border-radius: 10px;
  margin: 20px;
}


.category-group {
    border: 0.5px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: inset -10px -10px 15px rgba(255, 255, 255, 0.5), 
           inset 10px 10px 15px rgba(181, 181, 181, 0.12);
}

.category-title {
    margin-bottom: 10px;
    color: #3b3b3b;
    font-size: 1.2em;
}

.category-products {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.logo-image {
  height: 90px; /* Adjust as needed */
  width: auto;
  margin-right: 20px; /* Adjust as needed */
  border-radius: 10px;
  box-shadow: 0px 1px 2px rgba(0,0,0,0.1), 
            0px 2px 4px rgba(0,0,0,0.1), 
            0px 4px 8px rgba(0,0,0,0.1), 
            0px 8px 16px rgba(0,0,0,0.1);
}


.product-details-inputs {
    padding: 15px;
    margin-top: 10px;
    background-color: #f9f9f9; /* Light grey background */
    border: 1px solid #ddd; /* Light grey border */
    border-radius: 10px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between inputs */
}

.product-details-inputs input[type="number"],
.product-details-inputs input[type="date"],
.product-details-inputs label {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white;
}

.product-details-inputs button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #414141; /* Green background for the button */
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.product-details-inputs button:hover {
    background-color: #a3a5a3; /* Darker shade for hover effect */
}

.filtered-products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

}

.filtered-product-item {
  padding: 10px;
  background-color: #e0e0e0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.filtered-product-item:hover {
  background-color: #c8c8c8;
}


/* Media Queries for larger screens */
@media screen and (min-width: 600px) {
  .box {
    width: 150px; /* Adjusted for larger screens */
    height: 150px; /* Adjusted for larger screens */
  }

  .product-image {
    max-width: 100px; /* Adjusted for larger screens */
    max-height: 100px; /* Adjusted for larger screens */
  }

  .container {
    max-width: 1800px; /* Adjusted for larger screens */
  }

}

.category-box {
    border: 0.5px solid #e0e0e0;
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 20px;
    box-shadow: inset -10px -10px 15px rgba(255, 255, 255, 0.5), 
           inset 10px 10px 15px rgba(181, 181, 181, 0.12);
}

.category-box h3 {
  margin-top: 0;
}

.products {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@keyframes progress {
  0% {
      width: 0%;
  }
  100% {
      width: 100%;
  }
}

.animate-progress {
  width: 0; 
  animation: progress 0.75s ease-in-out forwards;
}