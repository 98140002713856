@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root,
[data-amplify-theme] {
  --amplify-colors-primary-10: #e2e8f0 !important;
  --amplify-colors-primary-20: #94a3b8 !important;
  --amplify-colors-primary-40: #475569 !important;
  --amplify-colors-primary-60: #334155 !important;
  --amplify-colors-primary-90: #0f172a !important;
  --amplify-colors-primary-80: black !important;
  --amplify-colors-primary-100: black !important;
}
