.html-display-box-content h1 {
  @apply text-3xl font-semibold mb-2;
}

.html-display-box-content h2 {
  @apply text-2xl font-semibold mb-2;
}

.html-display-box-content h3 {
  @apply text-xl font-semibold;
}

.html-display-box-content h4,
.html-display-box-content h5,
.html-display-box-content h6 {
  @apply text-lg font-semibold;
}

.html-display-box-content p {
  @apply mb-4;
}
