.react-calendar-timeline {
    @apply rounded;
    @apply overflow-hidden;
}

.react-calendar-timeline .rct-header-root,
.react-calendar-timeline .rct-dateHeader  {
     @apply bg-gray-100;
     @apply text-gray-900;
}

.react-calendar-timeline .rct-dateHeader-primary {
    @apply text-gray-900;
    @apply font-bold;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
     @apply bg-gray-50;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
     @apply bg-white;
}

.react-calendar-timeline .rct-item {
    @apply rounded-sm;
    @apply bg-blue-500 !important;
    @apply border-0 !important;
}

.react-calendar-timeline .rct-horizontal-lines .rct-hl-even, 
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd,
.react-calendar-timeline .rct-sidebar .rct-sidebar-row,
.react-calendar-timeline .rct-dateHeader,
.react-calendar-timeline .rct-calendar-header  {
    @apply border-0;
}

.react-calendar-timeline .rct-sidebar,
.react-calendar-timeline .rct-header-root,
.react-calendar-timeline .rct-vertical-lines .rct-vl {
    @apply border-gray-300;
}
